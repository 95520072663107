<template>
    <div>
        <Loader v-if="loading"/>
        <b-row>
            <b-col cols="5">
                <b-form-group label="Imóvel" label-for="immobile">
                    <b-form-input v-model="investment.immobileName" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Investidor" label-for="immobile">
                    <b-form-input v-model="investment.userInvestorProfileName" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Forma de Pagamento" label-for="payment-type">
                    <b-form-input v-model="investment.investmentQuota.paymentType" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Qtd de Cotas" label-for="payment-type">
                    <b-form-input v-model="investment.investmentQuota.qtd" disabled />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2">
                <b-form-group label="Valor da Cota" label-for="payment-type">
                  <b-form-input  :value="formatCurrencyPrice(investment.investmentQuota.quotaValue)" disabled/>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Total Investido" label-for="payment-type">
                  <b-form-input  :value="formatCurrencyPrice(investment.investmentQuota.total)" disabled/>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Data da Compra" label-for="payment-type">
                    <flat-pickr 
                        v-model="investment.investmentQuota.date"
                        :config="config"
                        class="form-control"
                        name="date"
                        :locale="Portuguese"
                        placeholder="Data da Compra" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group label="Status da Cota" label-for="status-cota">
                    <b-form-select
                        id="status-cota"
                        v-model="investment.investmentQuota.situation"
                        label="Status da Cota"
                        :options="listStatusCota" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3">
                <b-form-group label="Corretor" label-for="payment-type">
                    <b-form-input v-model="investment.associatedBroker.name" disabled />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-button
                  style="width: 100%; margin-top: 1.5em; margin-bottom: 1em; background-color: #f8ac18 !important"
                  v-ripple.400="'rgba(255, 255, 255, 0.5)'"
                  class="btn btn-primary ml-12"
                  variant="primary"
                  @click="$bvModal.show('modal-corretor-select')">
                  Escolher
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Valor da Comissão" label-for="payment-type">
                  <b-form-input  :value="formatCurrencyPrice(investment.associatedBroker.value)" disabled/>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Data do Pagamento" label-for="payment-type">
                    <flat-pickr
                        v-model="investment.associatedBroker.datePayment"
                        :config="config"
                        class="form-control"
                        name="birthDate"
                        :locale="Portuguese"
                        placeholder="Data do Pagamento" />
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Status da comissão" label-for="status-comissao">
                    <b-form-select
                        id="status-comissao"
                        v-model="investment.associatedBroker.status"
                        label="comissao"
                        :options="listStatusComissao"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row style="float: right;">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mt-2 mr-1"
            v-if="investment.investmentQuota.paymentType === 'Boleto'" 
            @click="$bvModal.show('modal-confirm-reenvio')">
            Reenviar boleto
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mt-2 mr-1"
            @click="save">
            Salvar
          </b-button>
        </b-row>
        <b-modal class="modal-personal" size="lg" id="modal-corretor-select" hide-footer>
            <div class="d-block text-center">
            <BrokerAssociated @selected="brokerSelected"></BrokerAssociated>
            </div>
        </b-modal>
        <b-modal id="modal-confirm-reenvio" centered class="modal-confirm-reenvio" @ok="reenviarBoleto"
            okTitle="Sim" cancelTitle="Não"> Realmente deseja reenviar o boleto?</b-modal>
    </div>
</template>

<script>
import {  BPagination, BFormSelect, BFormGroup, BLink, BRow, BCol, BFormInput, BModal, BButton  } from 'bootstrap-vue'
import  BrokerAssociated  from '../../checkout-oppotunity/BrokerAssociated.vue'
import InvestmentService from '@/services/Investment/InvestmentService'
import BrokerInvestmentService from '@/services/Investment/BrokerInvestmentService'
import MoneyInput from '@/components/MoneyInput.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import formatter from '@/mixins/formatter'
import toast from '@/mixins/toast'
import Ripple from 'vue-ripple-directive'
import InvestmentSituationEnum from '@/enums/InvestmentSituationEnum'
import PaymentService from '@/services/Investment/PaymentService'
import Loader from '@/components/Loader.vue'

export default {
    components: {
        BPagination,
        BFormSelect,
        BFormGroup,
        BButton,
        BLink,
        BRow, 
        MoneyInput,
        BCol, 
        BFormInput,
        BrokerAssociated,
        BModal,
        Loader,
        flatPickr
    },
    created() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    },
    destroyed() {
        this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    },
    mixins:[formatter, toast],
    directives: {
        Ripple,
    },

    data(){
        return {
            investment: { associatedBroker: {name: null, value: null, id: null, userBrokerProfileId: null, datePayment: null, status: null}},
            investmentId: null,
            loading: false,
            corretor: {name: null, id: null},
            listStatusCota: InvestmentSituationEnum.listStatusCota,
            listStatusComissao: InvestmentSituationEnum.listStatusComissao,
            config: {
                dateFormat: 'd/m/Y',
                locale: Portuguese,
            },
            
        }
    },

    methods: {
        async getInvestment(){
            this.investment = (await InvestmentService.getInvestmentQuota(this.investmentId)).data.result
            this.investment.investmentQuota.date = formatter.flatPickrDateFormat(this.investment.investmentQuota.date)
            if(this.investment.associatedBroker === null) {
                this.investment.associatedBroker = {value: this.investment.investmentQuota.total * 0.01, status: 2}
            } else {
                this.investment.associatedBroker.datePayment = formatter.flatPickrDateFormat(this.investment.associatedBroker.datePayment)
            }
        },
        async brokerSelected($event) {
            this.corretor = $event
            this.$bvModal.hide('modal-corretor-select')
            await BrokerInvestmentService.createOrEdit({ userBrokerProfileId: this.corretor.id, investmentQuotaId: this.investmentId })
            this.investment.associatedBroker = {
                id: this.investment.associatedBroker.id,
                name: this.corretor.name,
                userBrokerProfileId: this.corretor.id,
                value: this.investment.associatedBroker.value,
                datePayment: this.investment.associatedBroker.datePayment,
                status: this.investment.associatedBroker.status
            }
        },

        async save(){
            const {associatedBroker} = this.investment        
            if(associatedBroker.status === 1 && associatedBroker.datePayment === undefined)      {
                this.msgError('Informe a data de pagamento')
                return
            }
            this.investment.investmentQuota.associatedBroker = this.investment.associatedBroker
            try {
                await InvestmentService.CreateOrEdit(this.investment.investmentQuota)
                this.msgSuccess('Salvo com sucesso')
            } catch(error){
                this.msgError(error.response.data.error.message)
            }
        },

        async reenviarBoleto(){
            try {
                this.loading = true
                await PaymentService.reenviarBoleto(this.investment.investmentQuota.id)
                this.msgSuccess('Boleto reenviado com sucesso!')
                this.loading = false
            } catch(error){
                this.msgError(error.response.data.error.message)
                this.loading = false
            }
        }
    },

    mounted() {        
        this.investmentId = this.$route.params['id']
        this.getInvestment()
    }
}
</script>
<style>
</style>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '../../sass/variables.scss';
    @import '../../sass/company';
</style>
